import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subscription, map } from 'rxjs';
import { members } from '../../models/organisation.model';
import { ButtonComponent } from '../../shared/button/button.component';
import { IconComponent } from '../../shared/icon/icon.component';
import { SideHeaderComponent } from '../../shared/side-header/side-header.component';
import { AdvertiserDialogComponent } from '../../shared/tables/advertisers/advertiser-dialog/advertiser-dialog.component';
import { AdvertisersTableComponent } from '../../shared/tables/advertisers/advertisers-table.component';
import { CampaignGroupsTableComponent } from '../../shared/tables/campaign-groups/campaign-groups-table.component';
import { MembersTableComponent } from '../../shared/tables/members/members-table.component';
import { PaymentComponent } from '../../shared/tables/payment/payment.component';
import { AuthService } from '../auth/auth.service';
import { UtilityViewModelService } from '../view-model/utility-vm.service';

@Component({
    selector: 'advocate-ui-organisation-groups',
    standalone: true,
    imports: [CommonModule, IconComponent, MatButtonModule, ButtonComponent,
        SideHeaderComponent, PaymentComponent,
        AdvertisersTableComponent, MembersTableComponent, CampaignGroupsTableComponent],
    templateUrl: './organisation-groups.component.html',
    styleUrls: ['./organisation-groups.component.scss']
})
export class OrganisationGroupsComponent {
    managedModeOff = this.authService.getManagedModeOff();
    readOnlyUser = this.authService.getreadonlyUser();
    private subs = new Subscription();
    data?: {name: string, debit: number};
    paymentDataSource$: Observable<{header: string, details: string}[]> = this.utilityVMService.getOrganisationDataEffect().pipe(
        map(data => [
          { header: 'Method', details: data.organisation.payment_method_label },
          { header: 'Information', details: data.organisation.payment_information },
          { header: 'Currency', details: data.organisation.currency_label },
          { header: 'Debit', details: data.organisation.debit + '' },
          { header: 'Spend', details: data.organisation.spend + '' },
          { header: 'Balance', details: data.organisation.balance + '' },
        ])
      );

      memberDataSource$: Observable<members[]> = this.utilityVMService.getOrganisationDataEffect().pipe(
        map(data => data.organisation.members)
      )

    constructor(private route: Router, private dialog: MatDialog, private utilityVMService: UtilityViewModelService, private authService: AuthService) {}

    navigateToHome(): void {
        this.route.navigate(['/home'])
    }

    addAdvertisers(): void {
        const dialogRef = this.dialog.open(AdvertiserDialogComponent, {
            width: '61rem',
            height: '30rem',
            data: {name: null, debit: null, mode: 'add'},
        });

        this.subs.add(dialogRef.afterClosed().subscribe(result => {
            if (result?.name !== null && result?.debit !== null) this.data = result;
          }));
    }
}