export type YoutubeAgeGroupEnum = '18-24' | '25-34' | '35-44' | '45-54' | '55-64' | '65+' | 'Unknown';

export const YoutubeAgeGroupEnum = {
    Age1: '18-24' as YoutubeAgeGroupEnum,
    Age2: '25-34' as YoutubeAgeGroupEnum,
    Age3: '35-44' as YoutubeAgeGroupEnum,
    Age4: '45-54' as YoutubeAgeGroupEnum,
    Age5: '55-64' as YoutubeAgeGroupEnum,
    Age6: '65+' as YoutubeAgeGroupEnum,
    Unknown: 'Unknown' as YoutubeAgeGroupEnum
}

export type YoutubeDevicesEnum = 'Mobile' | 'Tablet' | 'Laptop' | 'CTV';

export const YoutubeDevicesEnum = {
    Mobile: 'Mobile' as YoutubeDevicesEnum,
    Tablet: 'Tablet' as YoutubeDevicesEnum,
    Laptop: 'Laptop' as YoutubeDevicesEnum,
    CTV: 'CTV' as YoutubeDevicesEnum
}

export type YoutubeGendersEnum = 'Male' | 'Female' | 'Unknown';

export const YoutubeGendersEnum = {
    Male: 'Male' as YoutubeGendersEnum,
    Female: 'Female' as YoutubeGendersEnum,
    Unknown: 'Unknown' as YoutubeGendersEnum
}

export type PlatformTypeEnum = 'Programmatic' | 'Search' | 'YouTube';

export const PlatformTypeEnum = {
    Programmatic: 'Programmatic' as PlatformTypeEnum,
    Search: 'Search' as PlatformTypeEnum,
    YouTube: 'YouTube' as PlatformTypeEnum
}

export type ObjectiveTypeEnum = 'Awareness' | 'Persuasion' | 'Acquisition' | 'Mobilisation';

export const ObjectiveTypeEnum = {
    Awareness: 'Awareness' as ObjectiveTypeEnum,
    Persuasion: 'Persuasion' as ObjectiveTypeEnum,
    Acquisition: 'Acquisition' as ObjectiveTypeEnum,
    Mobilisation: 'Mobilisation' as ObjectiveTypeEnum
}

export type CreativeTypeEnum = 'Audio' | 'Native' | 'Display' | 'Video' | 'CTV';

export const CreativeTypeEnum = {
    Native: 'Native' as CreativeTypeEnum,
    Display: 'Display' as CreativeTypeEnum,
    Video: 'Video' as CreativeTypeEnum,
    CTV: 'CTV' as CreativeTypeEnum,
    AUDIO: 'Audio' as CreativeTypeEnum
}

export type FileScopeTypeEnum = 'uncategorised' | 'campaign' | 'geofencing';

export const FileScopeTypeEnum = {
    Uncategorised: 'uncategorised' as FileScopeTypeEnum,
    Campaign: 'campaign' as FileScopeTypeEnum,
    Geofencing: 'geofencing' as FileScopeTypeEnum
}

export type YouTubeAdFormatEnum = 'YouTube Bumper' | 'YouTube Skippable' | 'YouTube Non-skippable' | 'YouTube Audio';

export const YouTubeAdFormatEnum = {
    Bumper: 'YouTube Bumper' as YouTubeAdFormatEnum,
    Skippable: 'YouTube Skippable' as YouTubeAdFormatEnum,
    NonSkippable: 'YouTube Non-skippable' as YouTubeAdFormatEnum,
    Audio: 'YouTube Audio' as YouTubeAdFormatEnum,
}

export type ContactUsEnum = 'send_request' | 'contact_us' | 'talk_to_manager' | 'request_help';

export const ContactUsEnum = {
    Request: 'send_request' as ContactUsEnum,
    Contact: 'contact_us' as ContactUsEnum,
    Manager: 'talk_to_manager' as ContactUsEnum,
    Help: 'request_help' as ContactUsEnum,
}

export type CountryEnum = 'united states' | 'united kingdom';

export const CountryEnum = {
    USA: 'united states' as CountryEnum,
    UK: 'united kingdom' as CountryEnum,
}