import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { AdvertiserItemModel, AdvertiserModel, AdvertisersDataModel } from '../models';

@Injectable({
    providedIn: 'root',
})
export class AdvertiserService {
    private apiUrl = environment.apiKey;; // Replace with your API URL
    constructor(private http: HttpClient) { }

    getAdvertiserData(orgId: number, page?: number, per_page?: number): Observable<AdvertisersDataModel> {
        const url = `${this.apiUrl}/organisations/${orgId}/advertisers`;
        const params: any = {};
        if (page) params.page = page;
        if (per_page) params.per_page = per_page;
        return this.http.get<AdvertisersDataModel>(url, { params });
    }

    createAdvertiser(orgId: number, advertiserDetail: AdvertiserModel): Observable<AdvertiserItemModel> {
        return this.http.post<AdvertiserItemModel>(`${this.apiUrl}/organisations/${orgId}/advertisers`, advertiserDetail);
    }

    editAdvertiser(orgId: number, advId: number, advertiserDetail: AdvertiserModel): Observable<AdvertiserItemModel> {
        return this.http.patch<AdvertiserItemModel>(`${this.apiUrl}/organisations/${orgId}/advertisers/${advId}`, advertiserDetail);
    }
}