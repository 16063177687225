import { AdvertiserItemModel } from "./advertiser.model"

export interface members {
    name: string,
    email: string
}

export interface OrganisationResponse {
    organisation: {
      id: number,
      name: string,
      members: [members],
      advertisers: [AdvertiserItemModel],
      managed_mode_id: number,
      managed_mode_label: string,
      payment_method_id: number,
      payment_method_label: string,
      payment_information: string,
      currency_id: number,
      currency_label: string,
      debit: number,
      spend: number,
      balance: number,
      audiences: Audience[]
    }
}

export interface MetricsModel {
  clicks: number,
  cpc: number,
  ctr: number,
  ecpm: number,
  impressions: number,
  source_date: string,
  true_cost: number,
  vcr: number,
  video_completions: number,
  video_plays: number,
  x: string
}

export interface OrganisationMetricsResponse {
  metrics: {
    props: [
      string
    ],
    weighted_totals: MetricsModel,
    simplified: {
      timestamps: [
        number
      ],
      series: [
        {
          data: [
            number
          ]
        }
      ]
    },
    full: MetricsModel[]
  },
}

export interface Audience {
  id: number;
  name: string;
  platform_id: number;
  platform_label: string;
}