export type ButtonSize = 'extra-small'|'small'|'medium'|'large'|'extra-large';

export const ButtonSize = {
    ExtraSmall: 'extra-small' as ButtonSize,
    Small: 'small' as ButtonSize,
    Medium: 'medium' as ButtonSize,
    Large: 'large' as ButtonSize,
    ExtraLarge: 'extra-large' as ButtonSize
}

export const DefaultButtonSize = ButtonSize.Small;


