<div class="container">
  <h2 class="container_header">{{ dialogTitle }}</h2>
  <form class="advocate-form" [formGroup]="form">
    <div class="advocate-form-row">
      <div class="advocate-form-row__label advocate-form-label">
        <label for="name" class="advocate-form-label__content">Name</label>
        <span class="advocate-required-field">&nbsp;</span>
      </div>

      <div class="advocate-form-row__content">
        <input type="text" id="name" autocomplete="off" formControlName="name"
          class="cux-textbox cux-textbox--stretched"
          [ngClass]="{'cux-textbox--error': errorSignal() && errorSignal()?.name?.[0]}" />

        <div class="advocate-help-text" *ngIf="errorSignal() && errorSignal()?.name">
          <p class="advocate-help-text--error">{{ errorSignal()?.name?.[0]}}
          </p>
        </div>
      </div>
    </div>

    <div class="advocate-form-row">
      <div class="advocate-form-row__label advocate-form-label">
        <label for="subject" class="advocate-form-label__content">Assigned debit</label>
        <span class="advocate-required-field">&nbsp;</span>
      </div>

      <div class="advocate-form-row__content">
        <input type="number" id="subject" autocomplete="off" formControlName="debit"
          class="cux-textbox cux-textbox--stretched" step="0.01" placeholder="0.00" pattern="^\d*(\.\d{0,2})?$"
          [ngClass]="{'cux-textbox--error': form.get('debit')?.errors?.['pattern'] || (errorSignal() && errorSignal()?.debit?.[0])}" />

        <div class="advocate-help-text" *ngIf="form.get('debit')?.errors?.['pattern']">
          <p class="advocate-help-text--error">Invalid number format: only non-negative numbers with up to 2 decimal
            places are allowed.</p>
        </div>

        <div class="advocate-help-text" *ngIf="errorSignal() && errorSignal()?.debit">
          <p class="advocate-help-text--error">{{ errorSignal()?.debit?.[0]}}
          </p>
        </div>

        <div class="advocate-help-text" *ngIf="errorSignal() && errorSignal()?.generalError">
          <p class="advocate-help-text--error">{{ errorSignal()?.generalError}}
          </p>
        </div>
      </div>

    </div>
    <div class="advocate-form-row__button">
      <advocate-ui-button [isCurved]="true"
        [state]="form.invalid ? 'Disabled' : data.mode === 'add' ? 'primary' : 'secondary'" size="small"
        name="{{data.mode === 'add' ? 'Add' : 'Save'}}" (click)="saveEditedData()"
        [disabled]="form.invalid"></advocate-ui-button>
    </div>
  </form>
</div>