import { Pipe, PipeTransform } from '@angular/core';

import { UIState } from '../utility';

@Pipe({
  standalone: true,
  name: 'iconState',
  pure: true
})
export class IconStatePipe implements PipeTransform {
  transform(value: string): string {
    let state = '';
    switch (value) {
      case UIState.Primary:
        state = 'cux-icon--primary';
        break;
      case UIState.Success:
        state = 'cux-icon--success';
        break;
      case UIState.Info:
        state = 'cux-icon--info';
        break;
      case UIState.Danger:
        state = 'cux-icon--danger';
        break;
      case UIState.Muted:
        state = 'cux-icon--muted';
        break;
      case UIState.Transparent:
        state = 'cux-icon--white';
        break;
    }
    return state;
  }

}
