import { Creative, Location } from "./campaigns.model";
import { SearchStep2, SearchStep3, SearchStep4 } from "./search-steps.model";

export interface Step0 {
    id?: number;
    platform_id: number;
    type_id?: number;
}

export interface Step1 {
    advertiser_id: number;
    name: string;
}

export interface Step2 {
    objective_id: number;
}

export interface Step3 {
    creative: Creative;
}

export interface Step4 {
    start_date: string;
    start_time: string;
    start_timezone_id: number;
    end_date: string;
    end_time: string;
    end_timezone_id: number;
    lifetime_budget: number;
    daily_budget?: number;
    budget_distribution_id?: number;
    running_days: string[];
    running_time_from: string;
    running_time_to: string;
    max_bid?: number;
    bid?: number
}

export interface ProximityTarget {
    id?: number;
    active?: boolean;
    address?: string;
    radius: number;
    radius_unit_id: number;
    radius_unit_label?: string;
    latitude: number;
    longitude: number;
}

export interface ProgrammaticProximityTarget {
    id: number;
    radius: number;
    radius_unit_label: string;
    latitude: number;
    longitude: number;
}

export interface ProximityTargetResponse {
    proximity_target: ProximityTarget
}

export interface ProximityErrorResponse {
    latitude?: string[],
    longitude?: string[],
    radius?: string[],
    message?: string
}

export interface Step5 {
    demographic_countries?: string[];
    locations: Location[];
    zip_codes?: string[];
    geofencing_file_id?: number;
    genders?: string[];
    age_groups?: string[];
    education?: string[];
    interests?: string[];
    keyword_language?: string;
    whitelisted_keywords?: string[];
    blacklisted_keywords?: string[];
    whitelisted_domains?: string[];
    blacklisted_domains?: string[];
    devices?: string[];
    languages?: { id: number; name: string }[];
    frequency_cap?: number;
    frequency_cap_interval?: number;
    frequency_cap_interval_type_id?: number;
    is_political?: boolean;
    environments?: string[];
    proximity_targets?: ProximityTarget[] | { id: number }[];
    audience_id?: number;
    has_premium_non_skippable_video?: boolean | null;
    has_premium_in_stream_video?: boolean | null;
    has_premium_placements?: boolean | null;
    has_premium_mobile_apps_only?: boolean | null;
    contract_ids?: number[];
    geofence_ids?: number[];
}

export interface CampaignSteps {
    step0?: Step0;
    step1?: Step1;
    step2?: Step2 | SearchStep2[];
    step3?: Step3[] | SearchStep3;
    step4?: Step4 | SearchStep4;
    step5?: Step5;
}

export interface CampaignPostResponseData {
    campaign: {
        step0: Step0;
        step1: Step1;
    };
    errors: any[]; // You might want to replace 'any' with the actual type for errors
}

export interface CampaignGetReponseData {
    campaign: {
        step0: Step0,
        step1: Step1,
        step2: Step2,
        step3: Step3,
        step4: Step4,
        step5: Step5,
    }
}
