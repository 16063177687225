import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class TokenRefreshInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // Add X-Advocate-Realm header
        const headers = new HttpHeaders({
            'X-Advocate-Realm': 'web'
        });

        const authRequest = request.clone({
            headers,
            withCredentials: true
        });

        return next.handle(authRequest).pipe(
            catchError((err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        console.error('Unauthorized request:', err);
                        this.authService.logout().catch(logoutError => {
                            console.error('Logout failed', logoutError);
                        });
                    } else {
                        console.error('HTTP error:', err);
                    }
                } else {
                    console.error('An error occurred:', err);
                }
                return throwError(() => err);
            })
        );
    }
}
