import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RouteGuard } from '../services/route-guard.service';
import { ImpersonateAdminComponent } from './admin/impersonate-app.component';
import { LoginGuard } from './auth/login-guard';
import { LoginComponent } from './auth/login/login.component';
import { ResetPasswordGuard } from './auth/reset-password.guard';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { LandingPageComponent } from './home/landing-page.component';
import { CampaignTableInfoComponent } from './reports/campaign-table-info/campaign-table-info.component';

export const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'admin/impersonate/:userId', component: ImpersonateAdminComponent },
  {
    path: 'user/reset-password',
    component: ResetPasswordComponent,
    canActivate: [ResetPasswordGuard]  // Guard to check for token
  },
  {
    path: 'home',
    loadComponent: () => import('./home/home.component').then(h => h.HomeComponent),
    children: [
      {
        path: '',
        component: LandingPageComponent,
      },
      {
        path: 'campaign',
        loadComponent: () => import('./campaign/new-campaign.component').then(n => n.NewCampaignComponent),
      },
      {
        path: 'programmatic/creative-options',
        loadComponent: () => import('./campaign/programmatic-creative-options/programmatic-creative-options.component').then(p => p.ProgrammaticCreativeOptionsComponent),
      },
      {
        path: 'programmatic',
        loadComponent: () => import('./campaign/stepper/pragrammatic/programmatic-campaign-stepper.component').then(p => p.ProgrammaticCampaignStepperComponent),
        canActivate: [RouteGuard]
      },
      {
        path: 'search',
        loadComponent: () => import('./campaign/stepper/search/search-campaign-stepper.component').then(s => s.SearchCampaignStepperComponent),
        canActivate: [RouteGuard]
      },
      {
        path: 'youtube',
        loadComponent: () => import('./campaign/stepper/youtube/youtube-campaign-stepper.component').then(y => y.YoutubeCampaignStepperComponent),
        canActivate: [RouteGuard]
      },
      {
        path: 'reports',
        children: [
          {
            path: '',
            loadComponent: () => import('./reports/reports-home.component').then(h => h.ReportsHomeComponent),
            pathMatch: 'full'
          },
          {
            path: 'campaign-info/:id',
            component: CampaignTableInfoComponent,
          }
        ]
      },
      {
        path: 'organisation',
        loadComponent: () => import('./organisation/organisation-groups.component').then(o => o.OrganisationGroupsComponent),
      },
      {
        path: 'campaign_group_view',
        loadComponent: () => import('./organisation/campaign-group-view.component').then(c => c.CampaignGroupViewComponent),
      },
      {
        path: 'audience',
        loadComponent: () => import('./audience/audience.component').then(a => a.AudienceComponent),
      },
      {
        path: 'payment',
        loadComponent: () => import('./payment/payment-home.component').then(p => p.PaymentHomeComponent)
      },
      {
        path: 'faqs',
        loadComponent: () => import('./faqs/faqs.component').then(f => f.FaqsComponent)
      },
      {
        path: 'verification',
        loadComponent: () => import('./verification/verification.component').then(v => v.VerificationComponent)
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
