import { AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function uniqueHeadlinesValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const formGroup = control as FormGroup;

        let headlines = [
            formGroup.get('headline_1')?.value,
            formGroup.get('headline_2')?.value,
            formGroup.get('headline_3')?.value
        ];

        // Get values from the FormArray and filter out empty values
        const additionalHeadlines = (formGroup.get('additional_headlines') as FormArray)?.controls
            .map(c => c.value)
            .filter(value => value != null && value.trim() !== '');

        // Combine and filter out empty values
        headlines = headlines.concat(additionalHeadlines).filter(value => value != null && value.trim() !== '');

        const uniqueHeadlines = new Set(headlines);
        if (uniqueHeadlines.size !== headlines.length) {
            return { nonUniqueHeadlines: true };
        }
        return null;
    };
}

export function uniqueDescriptionsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const formGroup = control as FormGroup;

        let descriptions = [
            formGroup.get('description_1')?.value,
            formGroup.get('description_2')?.value
        ];

        // Get values from the FormArray and filter out empty values
        const additionaldescriptions = (formGroup.get('additional_descriptions') as FormArray)?.controls
            .map(c => c.value)
            .filter(value => value != null && value.trim() !== '');

        // Combine and filter out empty values
        descriptions = descriptions.concat(additionaldescriptions).filter(value => value != null && value.trim() !== '');

        const uniqueDescriptions = new Set(descriptions);
        if (uniqueDescriptions.size !== descriptions.length) {
            return { nonUniqueDescriptions: true };
        }
        return null;
    };
}

// Custom validator function to compare two fields for equality
export function passwordMatchValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors['passwordMismatch']) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // Set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ passwordMismatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}
