<div class="container">
    <div class="container__login">
        <div class="login_panel" [class.login_panel__signup]="!loginScreen">
            <div class="left-panel">
                <div class="left-panel__header">
                    <div class="left-panel__wrapper">
                        <p class="left-panel__header__login" [class.left-panel__header__active]="loginScreen"
                            (click)="loginScreenToggle(true)">Log in</p>
                        <!-- <p class="left-panel__header__signup" [class.left-panel__header__active]="!loginScreen"
                            (click)="loginScreenToggle(false)">Sign up</p> -->
                    </div>
                </div>

                <div class="left-panel__body" *ngIf="loginScreen; else signupScreen">
                    <form [formGroup]="loginForm" class="advocate-form">
                        <div class="advocate-form-row">
                            <div class="advocate-form-row__label advocate-form-label">
                                <label for="email" class="advocate-form-label__content">Email</label>
                                <span class="advocate-required-field">&nbsp;</span>
                            </div>

                            <div class="advocate-form-row__content">
                                <input data-cy="login-email" type="text" id="email" autocomplete="off"
                                    formControlName="email" class="cux-textbox cux-textbox--stretched"
                                    placeholder="Email"
                                    [ngClass]="{'cux-textbox--error': (loginForm.get('email')?.invalid && loginForm.get('email')?.touched) ||
                                    (loginErrorSignal() && loginErrorSignal()?.email?.[0]), 
                                    'cux-textbox--success': loginForm.get('email')?.valid && loginForm.get('email')?.touched}">

                                <advocate-icon *ngIf="loginForm.get('email')?.valid && loginForm.get('email')?.touched"
                                    size="extra-large" state="primary" class="cux-textbox__icon"
                                    name="check"></advocate-icon>

                                <advocate-icon
                                    *ngIf="loginForm.get('email')?.invalid && loginForm.get('email')?.touched"
                                    size="extra-large" state="danger" class="cux-textbox__icon"
                                    name="error"></advocate-icon>
                            </div>
                            <div class="advocate-help-text"
                                *ngIf="loginForm.get('email')?.invalid && loginForm.get('email')?.touched">
                                <p class="advocate-help-text--error">Enter an valid Email ID.</p>
                            </div>

                            <div class="advocate-help-text"
                                *ngIf="loginErrorSignal() && loginErrorSignal()?.email?.[0]">
                                <p class="advocate-help-text--error">{{loginErrorSignal()?.email?.[0]}}</p>
                            </div>
                        </div>

                        <div class="advocate-form-row">
                            <div class="advocate-form-row__label advocate-form-label">
                                <label for="password" class="advocate-form-label__content">Password</label>
                                <span class="advocate-required-field">&nbsp;</span>
                            </div>

                            <div class="advocate-form-row__content">
                                <input data-cy="login-password" type="{{ hidePassword ? 'password' : 'text' }}"
                                    id="password" autocomplete="off" formControlName="password"
                                    class="cux-textbox cux-textbox--stretched" placeholder="Password" [ngClass]="{'cux-textbox--error': (loginForm.get('password')?.errors?.['required'] && loginForm.get('password')?.touched)
                                    || (loginErrorSignal() && loginErrorSignal()?.password?.[0])}">

                                <advocate-icon size="extra-large" state="muted" class="cux-textbox__icon"
                                    name="visibility" (click)="togglePasswordVisibility()"></advocate-icon>
                            </div>
                            <div class="advocate-help-text"
                                *ngIf="loginForm.get('password')?.errors?.['required'] && loginForm.get('password')?.touched">
                                <p class="advocate-help-text--error">Password is required</p>
                            </div>
                            <div class="advocate-help-text"
                                *ngIf="loginErrorSignal() && loginErrorSignal()?.password?.[0]">
                                <p class="advocate-help-text--error">{{loginErrorSignal()?.password?.[0]}}</p>
                            </div>
                        </div>

                        <div class="advocate-form-row" *ngIf="show2FAtextBox()">
                            <div class="advocate-form-row__label advocate-form-label">
                                <label for="two_factor_code" class="advocate-form-label__content">2FA code</label>
                                <span class="advocate-required-field">&nbsp;</span>
                            </div>
                            <!-- 2fa_required -->
                            <div class="advocate-form-row__content">
                                <input type="text" id="two_factor_code" autocomplete="off"
                                    formControlName="two_factor_code" class="cux-textbox cux-textbox--stretched"
                                    placeholder="2FA" [ngClass]="{'cux-textbox--error': (loginForm.get('two_factor_code')?.errors?.['required'] && loginForm.get('two_factor_code')?.touched)
                                || (loginErrorSignal() && loginErrorSignal()?.two_factor_code?.[0])}">
                            </div>

                            <div class="advocate-help-text"
                                *ngIf="loginForm.get('two_factor_code')?.errors?.['required'] && loginForm.get('two_factor_code')?.touched">
                                <p class="advocate-help-text--error">2FA from Authenticator app is required</p>
                            </div>
                            <div class="advocate-help-text"
                                *ngIf="loginErrorSignal() && loginErrorSignal()?.two_factor_code?.[0]">
                                <p class="advocate-help-text--error">{{loginErrorSignal()?.two_factor_code?.[0]}}</p>
                            </div>
                            <div class="advocate-help-text"
                                *ngIf="loginErrorSignal() && loginErrorSignal()?.generalError">
                                <p class="advocate-help-text--error">{{loginErrorSignal()?.generalError}}</p>
                            </div>
                        </div>
                        <div class="advocate-form-row">
                            <mat-checkbox data-cy="login-privacy-policy" [ngModelOptions]="{standalone: true}"
                                [(ngModel)]="acceptPrivacy" color="primary" class="advocate-checkbox">I accept
                                the <a class="advocate-checkbox__link" href="https://electica.io/privacy-policy">Privacy
                                    Policy</a> of Electica</mat-checkbox>
                        </div>

                        <div class="advocate-submit-row">
                            <div class="form-submission">
                                <span class="form-submission--forgot-password" (click)="forgotPassword()">Forgot your
                                    password?</span>
                                <advocate-ui-button data-cy="login-button"
                                    [disabled]="(!acceptPrivacy || loginForm.invalid)"
                                    [state]="acceptPrivacy && loginForm.valid ? uiState : 'Disabled'" [isCurved]="true"
                                    name="Login" (onChange)="login()"></advocate-ui-button>
                            </div>
                        </div>

                        <div class="advocate-submit-row">
                            <div class="align-success">
                                <p *ngIf="resetPasswordSignal()"><advocate-icon size="extra-large" state="success"
                                        name="check"></advocate-icon> </p>
                                <h4>{{resetPasswordSignal()}}</h4>
                            </div>
                        </div>
                    </form>
                </div>

                <ng-template #signupScreen>
                    <advocate-ui-signup (login)="loginScreenToggle(true)"></advocate-ui-signup>
                </ng-template>
            </div>

            <div class="right-panel">
                <div class="right-panel__header">
                    <h3 class="right-panel__header--text">Welcome to </h3>
                    <div class="right-panel__header__wrapper">
                        <img src="../../assets/advocate-ui-logo.png" class="right-panel__header__wrapper--logo">
                    </div>
                </div>
                <div class="right-panel__body">
                    <img src="../../assets/login-screen-image.png" alt="Login Screen logo" />
                </div>
            </div>
        </div>
    </div>
</div>