export interface ChangePasswordInterface {
    current_password: string,
    new_password: string,
    repeat_new_password: string
}

export interface LoginResponse {
    success: boolean,
    user: UserInfo;
}

export interface TokenInfo {
    token: string;
    created_at: string;
    expires_at: string;
}

interface UserInfo {
    id: number;
    email: string;
    name: string;
    phone: string;
    two_factor_enabled: boolean;
    organisation: OrganisationInfo;
    readonly: boolean;
}

interface OrganisationInfo {
    id: number;
    name: string;
    members: MemberInfo[];
    managed_mode_id: number;
    managed_mode_label: string;
    payment_method_id: number;
    payment_method_label: string;
    payment_information: string;
    currency_id: number;
    currency_label: string;
    debit: number;
    spend: number;
    balance: number;
    created_at: string;
    data: OrganisationData;
}

interface MemberInfo {
    name: string;
    email: string;
}

interface OrganisationData {
    cm360_id: string;
    dv360_id: string;
    managed_mode: number;
    sa360_google_ads_account_id: string;
    ttd_id: string;
    ttd_industry_id: string;
    ttd_industry_id_new: string;
    ttd_right_media_offer_type: string;
}

export interface ResetPasswordModel {
    token: string,
    new_password: string,
    repeat_new_password: string
}
