import { HttpClient } from '@angular/common/http';
import { Injectable, Signal, WritableSignal, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ChangePasswordInterface, LoginResponse, ResetPasswordModel, UserData } from '../../models';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private loginInfoState$ = new BehaviorSubject<UserData | null>(null);
    private apiUrl = environment.apiKey; // Replace with your API URL
    private managedModeOffSignal: WritableSignal<boolean> = signal(true);
    private readonlyUser: WritableSignal<boolean> = signal(false);

    getManagedModeOff(): Signal<boolean | null> {
        return this.managedModeOffSignal;
    }

    getreadonlyUser(): Signal<boolean | null> {
        return this.readonlyUser;
    }

    setLoginInfoState(userData: UserData | null): void {
        this.loginInfoState$.next(userData);
    }

    getLoginInfoState(): Observable<UserData | null> {
        return this.loginInfoState$;
    }

    isAuthenticated(): boolean {
        return false;
    }

    constructor(private http: HttpClient, private route: Router, private activatedRoute: ActivatedRoute) { }

    login(email: string, password: string, two_factor_code: string | undefined): Observable<LoginResponse | { two_factor_required: boolean }> {
        const body = two_factor_code ? { email, password, two_factor_code } : { email, password };
        return this.http.post<LoginResponse | { two_factor_required: boolean }>(`${this.apiUrl}/auth/login`, body)
            .pipe(
                map((response: LoginResponse | { two_factor_required: boolean }) => {
                    if ('two_factor_required' in response && typeof response.two_factor_required === 'boolean') {
                        // Return the response directly if it's the two_factor_code object
                        return response;
                    } else if (response && 'success' in response && response.success) {
                        response.user.organisation.managed_mode_id === 5 ? this.managedModeOffSignal.set(false) : this.managedModeOffSignal.set(true);
                        response.user.readonly ? this.readonlyUser.set(true) : this.readonlyUser.set(false);
                        localStorage.setItem('isLoggedIn', '1');
                        return response;
                    }
                    throw new Error('Invalid response structure');
                })
            );
    }


    forgotPassword(email: string): Observable<any> {
        const body = { email };
        return this.http.post<any>(`${this.apiUrl}/auth/request-password-reset`, body).pipe(
            map(() => 'The password reset email was sent out'))
    }

    resetPassword(resetPassword: ResetPasswordModel): Observable<any> {
        const body = {
            token: resetPassword.token,
            new_password: resetPassword.new_password,
            repeat_new_password: resetPassword.repeat_new_password
        };
        return this.http.post<any>(`${this.apiUrl}/auth/reset-password`, body);
    }


    logout(): Promise<any> {
        const logoutObservable = this.http.post<any>(`${this.apiUrl}/auth/logout`, null).pipe(tap(() => {
            localStorage.removeItem('isLoggedIn');
            this.route.navigate(['/login']);
        }));
        return firstValueFrom(logoutObservable);
    }

    loginInfo(): Observable<UserData> {
        return this.http.get<UserData>(`${this.apiUrl}/auth/info`).pipe(tap(d => this.setLoginInfoState(d)));
    }

    changePassword(orgId: number, userId: number, body: ChangePasswordInterface | { two_factor_code: string }): Observable<UserData> {
        return this.http.post<UserData>(`${this.apiUrl}/organisations/${orgId}/users/${userId}/account`, body);
    }

    impersonateUser(userId: string): Observable<UserData | null> {
        return this.http.get<UserData | null>(`${this.apiUrl}/admin/impersonate/${userId}`).pipe(tap(d => this.setLoginInfoState(d)));;
    }
}
