import { AdvocateOption } from "./generic.model";

export interface IsSelectedInterface {
    multiple: boolean;
    selectedArray?: AdvocateOption[];
    selectedObj?: AdvocateOption;
}

export interface SelectedObject {
    anArray: AdvocateOption[];
    currentOption: AdvocateOption;
}


export interface AdvocateCurrencyOptions {
    name: string;
    id: string;
}

export interface campaignFilterOptions {
    advertiser: AdvocateOption;
    name: string;
    status: AdvocateOption;
    type: AdvocateOption;
    includeArchive: number;
}