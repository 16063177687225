export type IconSize = 'extra-small'|'small'|'medium'|'large'|'extra-large';

export const IconSize = {
    ExtraSmall: 'extra-small' as IconSize,
    Small: 'small' as IconSize,
    Medium: 'medium' as IconSize,
    Large: 'large' as IconSize,
    ExtraLarge: 'extra-large' as IconSize
}

export const DefaultIconSize = IconSize.Small;


