import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, catchError, map, of, take } from 'rxjs';
import { UtilityViewModelService } from '../app/view-model/utility-vm.service';

@Injectable({
    providedIn: 'root'
})
export class RouteGuard implements CanActivate {

    constructor(
        private router: Router,
        private utilityVMService: UtilityViewModelService
    ) { }

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        return this.utilityVMService.getLoginInfoEffect().pipe(
            take(1),
            map(response => {
                if (response?.user.organisation.flags.is_political) {
                    if (response?.user.organisation.flags.can_political) return true;
                    else {
                        this.router.navigate(['/home/campaign']);
                        return false;
                    }
                } else return true;
            }),
            catchError((error) => {
                // Handle error, log it, or navigate to another route if necessary
                console.error('Error fetching is_political status:', error);
                this.router.navigate(['/home/campaign']);
                return of(false);
            })
        );
    }
}
