<div class="container" *ngIf="!optionSelected;else programSelected">
    <div class="container-header">
        <div class="container-header--text">
            <span class="container-header--text-welcome">Welcome !</span>
            <div class="container-header--text-content">
                <span>Welcome to Advocate, a programmatic ad targeting platform
                    for progressive campaigners, by progressive campaigners.</span>
            </div>

        </div>
        <div class="container-header--image">
            <img class="container-header--image-png" src="../../assets/home_page.png" alt="Home page ad image">
        </div>

    </div>
    <div class="container-body">
        <div data-cy="homepage-options" class="container-body--options" (mouseenter)="hoveredNewCampaigns = true"
            (mouseleave)="hoveredNewCampaigns = false" (click)="navigateTo('/home/campaign')">
            <button mat-fab color="muted" class="container-body--options-btn">
                <advocate-icon *ngIf="!hoveredNewCampaigns" [isSVG]="true" svgName="campaign-icon.svg"></advocate-icon>
                <advocate-icon *ngIf="hoveredNewCampaigns" [isSVG]="true"
                    svgName="campaign-icon-white.svg"></advocate-icon>
            </button>
            <div class="container-body--options-name">
                <span>New Campaign</span>
            </div>
            <div class="container-body--options-content">
                <span>This is where it begins – on the other side of this button
                    lives an entirely new way to get in touch with your
                    constituents and stakeholders. Let’s go!</span>
            </div>
        </div>
        <div data-cy="homepage-options" class="container-body--options" (mouseenter)="hoveredCampaigns = true"
            (mouseleave)="hoveredCampaigns = false" (click)="navigateTo('/home/reports')">
            <button mat-fab color="muted" class="container-body--options-btn">
                <advocate-icon *ngIf="!hoveredCampaigns" [isSVG]="true" svgName="reports-icon.svg"></advocate-icon>
                <advocate-icon *ngIf="hoveredCampaigns" [isSVG]="true" svgName="reports-icon-white.svg"></advocate-icon>
            </button>
            <div class="container-body--options-name">
                <span>Campaigns</span>
            </div>
            <div class="container-body--options-content">
                <span>Click here to see your live campaigns!</span>
            </div>
        </div>
        <div data-cy="homepage-options" class="container-body--options" (mouseenter)="hoveredOrganisation = true"
            (mouseleave)="hoveredOrganisation = false" (click)="navigateTo('/home/organisation')">
            <button mat-fab color="muted" class="container-body--options-btn">
                <advocate-icon *ngIf="!hoveredOrganisation" [isSVG]="true"
                    svgName="organisation-icon.svg"></advocate-icon>
                <advocate-icon *ngIf="hoveredOrganisation" [isSVG]="true"
                    svgName="organisation-icon-white.svg"></advocate-icon>
            </button>
            <div class="container-body--options-name">
                <span>Organisation</span>
            </div>
            <div class="container-body--options-content">
                <span>Your details and settings live here</span>
            </div>
        </div>
    </div>
</div>

<ng-template #programSelected>
    <router-outlet></router-outlet>
</ng-template>