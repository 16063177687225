import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { FormService } from '../../../../services/form.service';
import { ButtonComponent } from '../../../../shared/button/button.component';
import { ContactUsComponent } from '../../../../shared/contact-us/contact-us.component';

@Component({
  selector: 'advocate-ui-campaign-dialog-box',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ButtonComponent, MatCheckboxModule],
  templateUrl: './campaign-dialog-box.component.html',
  styleUrls: ['./campaign-dialog-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignDialogBoxComponent implements OnDestroy {
  private subs = new Subscription();
  checkboxForm!: FormGroup;
  contactForm = this.formService.contactUsGroup;
  options = ['List of top websites reached', 'Comparing creative assets’ performance', 'Impressions served per postal code'];

  constructor(
    public dialogRef: MatDialogRef<CampaignDialogBoxComponent>,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data?: { id: number }
  ) { }

  ngOnInit(): void {
    this.checkboxForm = this.formBuilder.group({
      options: this.formBuilder.array(this.options.map(() => false))
    });
  }

  sendRequest(option: string): void {
    const selectedOptions = this.checkboxForm.value.options
      .map((checked: boolean, i: number) => checked ? this.options[i] : null)
      .filter((value: string) => value !== null);
    this.dialogRef.close();
    const contactDialogRef = this.dialog.open(ContactUsComponent, {
      width: '55rem',
      height: '56rem',
      data: { selectedOption: option, campaignId: this.data?.id ?? 0, mailingOptions: selectedOptions }
    });

    this.subs.add(contactDialogRef.afterClosed().subscribe(() => {
      this.contactForm.reset();
    }));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
