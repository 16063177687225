import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DefaultState, UIState } from '../utility';

import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { ButtonSize, DefaultButtonSize } from './button-size.enum';
import { ButtonSizePipe } from './button-size.pipe';
import { ButtonStatePipe } from './button-state.pipe';

/**
 * Disabled: To make your button disabled, change the state and also the [disabled] var.
 */

@Component({
  standalone: true,
  imports: [CommonModule, ButtonStatePipe, ButtonSizePipe, MatButtonModule],
  selector: 'advocate-ui-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  /** Accepts an string value to be name of the button */
  @Input()
  name: string = '';

  /** Accespts a string value to set state of the button. UIStates for available states */
  @Input()
  state: UIState = DefaultState;

  /** Accepts a string value to set size of the button. button-size.enum.ts for available sizes */
  @Input()
  size: ButtonSize = DefaultButtonSize;

  /** Accepts an boolean value to disable the button. */
  @Input()
  disabled: boolean | undefined | null = false;

  /** Accepts an boolean value to be secondary button */
  @Input()
  isSecondary: boolean | null = false;

  /** Accepts an boolean value to add curved style to the button */
  @Input()
  isCurved: boolean | null = false;

  /** Triggers onClick to be handled on the respective components. For the same reason, we need to make this an directive rather being a component. This is for TODO */
  @Output()
  onChange = new EventEmitter<void>();

  onClick(): void {
    this.onChange.emit();
  }
}
