import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { OrganisationMetricsResponse, OrganisationResponse } from '../models/organisation.model';

@Injectable({
    providedIn: 'root',
})
export class OrganisationService {
    private apiUrl = environment.apiKey; // Replace with your API URL
    constructor(private http: HttpClient) { }

    getOrganisationData(orgId: number): Observable<OrganisationResponse> {
        return this.http.get<OrganisationResponse>(`${this.apiUrl}/organisations/${orgId}`);
    }

    getOrganisationMetrics(orgId: number, startDate?: string | undefined, endDate?: string | undefined, id?: number | undefined): Observable<OrganisationMetricsResponse> {
        const url = `${this.apiUrl}/organisations/${orgId}/metrics`;

        const params: any = {};
        if (startDate) params.from_date = startDate;
        if (endDate) params.to_date = endDate;
        if (id) params['campaign_ids[]'] = id;
        return this.http.get<OrganisationMetricsResponse>(url, { params });
    }
}