import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../environments/environment";
import { PaymentData } from "../models";

@Injectable({
    providedIn: 'root',
})
export class PaymentService {
    private apiUrl = environment.apiKey;; // Replace with your API URL
    constructor(private http: HttpClient) { }

    getPaymentLogs(orgId: number, page?: number, per_page?: number): Observable<PaymentData> {
        const url = `${this.apiUrl}/organisations/${orgId}/payments`;
        const params: any = {};
        if (page) params.page = page;
        if (per_page) params.per_page = per_page;
        return this.http.get<PaymentData>(url, { params });
    }

    postPayment(orgId: number, amount: number): Observable<{ redirect_to: string }> {
        const url = `${this.apiUrl}/organisations/${orgId}/payments`;
        return this.http.post<{ redirect_to: string }>(url, { amount, redirect: false });
    }
}