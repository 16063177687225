import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { PoliticalDataBody, PoliticalDataResponse, PostPoliticalDataResponse } from '../models/political-data.model';

@Injectable({
  providedIn: 'root'
})
export class PoliticalDataService {

  private apiUrl = environment.apiKey;
  constructor(private http: HttpClient) { }

  getPoliticalData(orgId: number, page?: number, per_page?: number): Observable<PoliticalDataResponse> {
    const params: any = {};
    if (page) params.page = page;
    if (per_page) params.per_page = per_page;
    return this.http.get<PoliticalDataResponse>(`${this.apiUrl}/organisations/${orgId}/political-data`, { params });
  }

  postPoliticalData(orgId: number, body: PoliticalDataBody): Observable<PostPoliticalDataResponse> {
    return this.http.post<PostPoliticalDataResponse>(`${this.apiUrl}/organisations/${orgId}/political-data`, body);
  }

  getPoliticalDataById(orgId: number, politicalId: number): Observable<PoliticalDataResponse> {
    return this.http.get<PoliticalDataResponse>(`${this.apiUrl}/organisations/${orgId}/political-data/${politicalId}`);
  }

  patchPoliticalDataById(orgId: number, politicalId: number, body: PoliticalDataBody): Observable<PostPoliticalDataResponse> {
    return this.http.patch<PostPoliticalDataResponse>(`${this.apiUrl}/organisations/${orgId}/political-data/${politicalId}`, body);
  }

}
