export interface Step1ErrorInterface {
    name?: string[];
    generalError?: string;
}

export interface CreativeStepErrorInterface {
    headline?: string[],
    text?: string[],
    name?: string[],
    sponsored_by?: string[],
    landing_url?: string[],
    generalError?: string,
    call_to_action_text?: string,
}

export interface YoutubeCreativeErrorInterface {
    type_id?: string[],
    sponsored_by?: string[],
    video_url?: string[],
    display_url?: string[],
    landing_url?: string[],
    generalError?: string
}

export interface BudgetErrorInterface {
    bid?: string[],
    lifetime_budget?: string[],
    daily_budget?: string[],
    start_date?: string[],
    end_date?: string[],
    max_bid?: string[],
    generalError?: string
}

export interface TargetingErrorInterface {
    demographic_countries?: string[],
    keyword_language?: string[],
    zip_codes?: string[],
    geofencing_file_id?: string[],
    locations?: string[],
    proximity_targets?: string[],
    frequency_cap_interval?: string[],
    frequency_cap?: string[],
    frequency_cap_interval_type_id?: string[],
    generalError?: string,
    contract_ids?: string[]
}

export interface SearchAdsErrorInterface {
    landing_url?: string[],
    headline_1?: string[],
    headline_2?: string[],
    headline_3?: string[],
    additional_headlines?: string[],
    description_1?: string[],
    description_2?: string[],
    generalError?: string
}

export interface SearchBudgetErrorInterface {
    cpc_bid?: string[],
    daily_budget?: string[],
    end_date?: string[],
    start_date?: string[],
    generalError?: string
}

export interface SearchKeywordErrorInterface {
    keyword?: string[],
    negative_keyword?: string[],
    generalError?: string
}

export interface ChangePasswordErrorInterface {
    current_password?: string[],
    new_password?: string[],
    repeat_new_password?: string[],
    generalError?: string
}

export interface AdvertiserErrorInterface {
    debit?: string[],
    name?: string[],
    generalError?: string
}