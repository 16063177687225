<h2>Members</h2>
<table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>
  
    <!-- Email Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>