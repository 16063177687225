import { Creative } from "./campaigns.model";
import { TabOptions } from "./tab-options";

export interface CreativeFileRequest {
    scope_id?: number;
    creative_type_id: number;
    campaign_id: number;
    file: any;
}

export interface CreativeFileModel {
    mime: string;
    loading: boolean;
    isError: boolean;
    error: string;
    file: File
}

export interface FileItem {
    id: number;
    name: string;
    client_name: string;
    mime: string;
    extension: string;
}

export interface FilesResponse {
    files: FileItem[];
}

export interface keywordItems {
    active: boolean,
    id: number,
    match_type_id: number,
    match_type_label: string,
    type_id: number,
    type_label: string
    keyword?: string
}

export interface KeywordResponse {
    keyword: keywordItems
}

export interface CreativesResponse {
    creatives: {
        items: Creative[];
        _links: {
            self: { href: string };
            first: { href: string };
            last: { href: string };
        };
        _meta: {
            totalCount: number;
            pageCount: number;
            currentPage: number;
            perPage: number;
        };
    }
}

export interface YoutubeCreativesForm {
    type_id: number;
    type_label: string;
    sponsoredBy: string;
    landingPageUrl: string;
    displayUrl: string;
    videoUrl: string;
    id: number;
}

export interface ProgrammaticCreativesForm {
    creativeType: string;
    fileId: number;
    file: string;
    name: string;
    sponsoredBy: string;
    landingPageUrl: string;
    retargeting: string;
    headline: string;
    id: number;
    statusId: number;
    statusLabel: string;
    text: string;
    typeId: number;
    typeLabel: string;
}

export interface ProgrammaticCreativesArrayForm {
    creativeType: TabOptions;
    fileId: number;
    file: string;
    name: string;
    sponsoredBy: string;
    landingPageUrl: string;
    retargeting: string;
    headline: string;
    id: number;
    statusId: number;
    statusLabel: string;
    text: string;
    typeId: number;
    typeLabel: string;
}