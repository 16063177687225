import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { uniqueDescriptionsValidator, uniqueHeadlinesValidator } from './validators';

@Injectable({
    providedIn: 'root',
})
export class FormService {
    atLeastOneRequiredValidator(controlNames: string[]): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const group = control as FormGroup;

            const controls = controlNames.map(name => group.get(name));

            const isAnyFilled = controls.some(control => {
                if (control instanceof FormArray) {
                    return control.length > 0;
                } else {
                    return !!control?.value && control.value.trim() !== '';
                }
            });

            return isAnyFilled ? null : { atLeastOneRequired: true };
        };
    }

    public form: FormGroup;
    public adsFormGroup: FormGroup = this.fb.group({
        headline_1: ['', [Validators.required, Validators.maxLength(30)]],
        headline_2: ['', [Validators.required, Validators.maxLength(30)]],
        headline_3: ['', [Validators.required, Validators.maxLength(30)]],
        additional_headlines: this.fb.array([]),
        description_1: ['', Validators.required],
        description_2: ['', Validators.required],
        additional_descriptions: this.fb.array([]),
        landing_url: ['', Validators.required],
        id: [],
        type_id: [],
        type_label: [''],
        status_id: [],
        status_label: ['']
    }, { validators: [uniqueHeadlinesValidator(), uniqueDescriptionsValidator()] });

    public proximityFormGroup: FormGroup = this.fb.group({
        id: [],
        active: [],
        address: [''],
        radius_unit_id: ['', Validators.required],
        radius_unit_label: [''],
        radius: [0, Validators.required],
        latitude: ['', Validators.required],
        longitude: ['', Validators.required],
    });

    public programmaticProximityFormGroup: FormGroup = this.fb.group({
        radius: [0, [Validators.required]],
        latitude: ['', [Validators.required, Validators.min(-90), Validators.max(90)]],
        longitude: ['', [Validators.required, Validators.min(-180), Validators.max(180)]],
        id: [],
        radius_unit_label: ['']
    });

    public utilityFormGroup: FormGroup = this.fb.group({
        id: [],
        name: ['']
    });

    public locationsFormGroup: FormGroup = this.fb.group({
        id: [],
        name: ['']
    });

    public ageGroupsFormGroup: FormGroup = this.fb.group({
        id: [],
        name: []
    });

    public runningDaysFormGroup: FormGroup = this.fb.group({
        id: [],
        name: []
    });

    public devicesFormGroup: FormGroup = this.fb.group({
        id: [],
        name: []
    });

    public genderGroupsFormGroup: FormGroup = this.fb.group({
        id: [],
        name: []
    });

    public languageFormGroup: FormGroup = this.fb.group({
        id: [],
        name: ['']
    });

    public interestsFormGroup: FormGroup = this.fb.group({
        id: [],
        name: ['']
    });

    public whiteListedKeywordsFormGroup: FormGroup = this.fb.group({
        id: [],
        name: ['']
    });

    public blackListedKeywordsFormGroup: FormGroup = this.fb.group({
        id: [],
        name: ['']
    });

    public whiteListedDomainsFormGroup: FormGroup = this.fb.group({
        id: [],
        name: ['']
    });

    public blackListedDomainsFormGroup: FormGroup = this.fb.group({
        id: [],
        name: ['']
    });

    public programmaticCreativeFormGroup: FormGroup = this.fb.group({
        creativeType: ['', [Validators.required]],
        fileId: [''],
        fileIds: this.fb.array([], [Validators.required]),  // Ensures at least one file ID is added
        file: [''],
        name: [''],
        sponsoredBy: ['', [Validators.required, Validators.maxLength(25)]],
        landingPageUrl: ['', [Validators.required]],
        retargeting: ['no'],
        headline: ['', Validators.maxLength(25)],
        id: [],
        statusId: [],
        statusLabel: [''],
        text: ['', Validators.maxLength(90)],
        typeId: [],
        typeLabel: [''],
        callToAction: ['', Validators.maxLength(15)]
    });

    public youtubeCreativeFormGroup: FormGroup = this.fb.group({
        type_id: ['', [Validators.required]],
        type_label: [''],
        sponsoredBy: ['', [Validators.required]],
        landingPageUrl: ['', [Validators.required]],
        displayUrl: ['', [Validators.required]],
        videoUrl: ['', [Validators.required]],
        id: ['']
    });

    public targetingKeywordFormGroup: FormGroup = this.fb.group({
        keyword: ['', Validators.required],
        match_type_label: ['', Validators.required],
        active: [false],
        id: [Validators.required],
        match_type_id: [Validators.required],
        type_id: [Validators.required],
        type_label: [Validators.required]
    });

    public negativeKeywordFormGroup: FormGroup = this.fb.group({
        keyword: ['', Validators.required],
        match_type_label: ['', Validators.required],
        active: [false],
        id: [Validators.required],
        match_type_id: [Validators.required],
        type_id: [Validators.required],
        type_label: [Validators.required]
    });

    public organisationVerificationFormGroup: FormGroup = this.fb.group({
        id: [Validators.required],
        name: ['', Validators.required],
        extension: ['', Validators.required],
        mime: [''],
        client_name: [''],
        loading: [false],
        error: ['']
    })

    constructor(private fb: FormBuilder) {
        this.form = this.fb.group({
            platformId: ['', [Validators.required]],
            campaignNameForm: this.fb.group({
                selectedAdvertiser: ['', [Validators.required]],
                advertiserName: [''],
                assignedDebit: [0],
                campaignName: ['', [Validators.required]],
            }),
            objectiveControl: ['', [Validators.required]],
            creativeFormGroup: this.fb.group({
                youtubeCreativesArray: this.fb.array([]),
                programmaticCreativesArray: this.fb.array([]),
            }),
            budgetFormGroup: this.fb.group({
                startDate: ['', [Validators.required]],
                startTime: ['', [Validators.required]],
                startTimeZoneId: ['', [Validators.required]],
                endDate: ['', [Validators.required]],
                endTime: ['', [Validators.required]],
                endTimeZoneId: ['', [Validators.required]],
                lifeTimeBudget: [0, [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
                dailyBudget: [0, [Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
                maxBid: [],
                budgetDistributionId: ['', Validators.required],
                runningDays: this.fb.array([]),
                bid: [],
                runningTimeFrom: [''],
                runningTimeTo: [''],
            }),
            targetingFormGroup: this.fb.group({
                demographicCountries: this.fb.array([]),
                locations: this.fb.array([]),
                ageGroups: this.fb.array([]),
                genderGroups: this.fb.array([]),
                postalCodes: [''],
                geofencing_file_id: [],
                proximityTargetsArray: this.fb.array([]),
                programmaticProximityArray: this.fb.array([]),
                isPoliticalCampaign: ['no'],
                environmentsArray: this.fb.array([]),
                devices: this.fb.array([]),
                languages: this.fb.array([]),
                interests: this.fb.array([]),
                keywordLanguage: [''],
                whiteListedKeywords: this.fb.array([]),
                blackListedKeywords: this.fb.array([]),
                whiteListedDomains: this.fb.array([]),
                blackListedDomains: this.fb.array([]),
                frequencyCap: ['', [Validators.pattern(/^\d+$/), Validators.min(1)]],
                frequencyCapInterval: ['', [Validators.pattern(/^\d+$/), Validators.min(1)]],
                frequencyCapIntervalId: [],
                audience: [],
                premiumNonSkippableVideo: [],
                premiumInStreamVideo: [],
                premiumPlacements: [],
                premiumMobileAppsOnly: [],
                selectedContract: this.fb.array([]),
                geofenceList: this.fb.array([]),
                selectedGeofenceOption: ['']
            }, {
                validators: this.atLeastOneRequiredValidator([
                    'locations',
                    'postalCodes',
                    'proximityTargetsArray',
                    'programmaticProximityArray',
                    'geofenceList'
                ])
            }),
            advertiserDialogGroup: this.fb.group({
                name: [null, Validators.required],
                debit: [0, [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
            }),
            contactUsGroup: this.fb.group({
                name: ['', Validators.required],
                subject: ['', Validators.required],
                phone: [''],
                email: ['', Validators.required],
                message: ['', Validators.required],
                note: [''],
            }),
            adsCreativeArray: this.fb.array([]),
            searchBudgetGroup: this.fb.group({
                start_date: ['', Validators.required],
                end_date: ['', Validators.required],
                daily_budget: [0, [Validators.required, Validators.min(1), Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
                cpc_bid: [0, [Validators.required, Validators.min(0.01)]]
            }),
            keywordFormGroup: this.fb.group({
                targetingFormArray: this.fb.array([]),
                negativeFormArray: this.fb.array([])
            }),
            filterFormGroup: this.fb.group({
                type: [''],
                advertiser: [''],
                status: [''],
                name: [''],
                includeArchive: [0]
            }),
            verificationFormGroup: fb.group({
                organisationVerificationFormArray: fb.array([]),
                is_candidate: [false],
                candidate_name: ['']
            })
        });
    }

    get platformIdControl(): FormControl {
        return this.form.get('platformId') as FormControl;
    }

    get objectiveControl(): FormControl {
        return this.form.get('objectiveControl') as FormControl;
    }

    get campaignNameFormGroup(): FormGroup {
        return this.form.get('campaignNameForm') as FormGroup;
    }

    get creativeFormGroup(): FormGroup {
        return this.form.get('creativeFormGroup') as FormGroup;
    }

    get budgetFormGroup(): FormGroup {
        return this.form.get('budgetFormGroup') as FormGroup;
    }

    get targetingFormGroup(): FormGroup {
        return this.form.get('targetingFormGroup') as FormGroup;
    }

    get advertiserDialogGroup(): FormGroup {
        return this.form.get('advertiserDialogGroup') as FormGroup;
    }

    get programmaticProximityFormArray(): FormArray {
        return this.targetingFormGroup.get('programmaticProximityArray') as FormArray;
    }

    get proximityFormArray(): FormArray {
        return this.targetingFormGroup.get('proximityTargetsArray') as FormArray;
    }

    get locationsArray(): FormArray {
        return this.targetingFormGroup.get('locations') as FormArray;
    }

    get demographicCountriesArray(): FormArray {
        return this.targetingFormGroup.get('demographicCountries') as FormArray;
    }

    get ageGroupsArray(): FormArray {
        return this.targetingFormGroup.get('ageGroups') as FormArray;
    }

    get runningDaysArray(): FormArray {
        return this.budgetFormGroup.get('runningDays') as FormArray;
    }

    get genderGroupsArray(): FormArray {
        return this.targetingFormGroup.get('genderGroups') as FormArray;
    }

    get devicesGroupsArray(): FormArray {
        return this.targetingFormGroup.get('devices') as FormArray;
    }

    get environmentsArray(): FormArray {
        return this.targetingFormGroup.get('environmentsArray') as FormArray;
    }

    get interestsArray(): FormArray {
        return this.targetingFormGroup.get('interests') as FormArray;
    }

    get geofenceListArray(): FormArray {
        return this.targetingFormGroup.get('geofenceList') as FormArray;
    }

    get whiteListedKeywordsArray(): FormArray {
        return this.targetingFormGroup.get('whiteListedKeywords') as FormArray;
    }

    get blackListedKeywordsArray(): FormArray {
        return this.targetingFormGroup.get('blackListedKeywords') as FormArray;
    }

    get whiteListedDomainsArray(): FormArray {
        return this.targetingFormGroup.get('whiteListedDomains') as FormArray;
    }

    get blackListedDomainsArray(): FormArray {
        return this.targetingFormGroup.get('blackListedDomains') as FormArray;
    }

    get languagesArray(): FormArray {
        return this.targetingFormGroup.get('languages') as FormArray;
    }

    get programmaticCreativesArray(): FormArray {
        return this.creativeFormGroup.get('programmaticCreativesArray') as FormArray;
    }

    get youtubeCreativesArray(): FormArray {
        return this.creativeFormGroup.get('youtubeCreativesArray') as FormArray;
    }

    get contactUsGroup(): FormGroup {
        return this.form.get('contactUsGroup') as FormGroup;
    }

    get adsCreativeArray(): FormArray {
        return this.form.get('adsCreativeArray') as FormArray;
    }

    get headlinesFormArray(): FormArray {
        return this.adsFormGroup.get('additional_headlines') as FormArray;
    }

    get descriptionsFormArray(): FormArray {
        return this.adsFormGroup.get('additional_descriptions') as FormArray;
    }

    get searchBudgetGroup(): FormGroup {
        return this.form.get('searchBudgetGroup') as FormGroup;
    }

    get keywordFormGroup(): FormGroup {
        return this.form.get('keywordFormGroup') as FormGroup;
    }

    get filterFormGroup(): FormGroup {
        return this.form.get('filterFormGroup') as FormGroup;
    }

    get targetingFormArray(): FormArray {
        return this.keywordFormGroup.get('targetingFormArray') as FormArray
    }

    get negativeFormArray(): FormArray {
        return this.keywordFormGroup.get('negativeFormArray') as FormArray
    }

    get contractIdArray(): FormArray {
        return this.targetingFormGroup.get('selectedContract') as FormArray;
    }

    get verificationFormGroup(): FormGroup {
        return this.form.get('verificationFormGroup') as FormGroup;
    }

    get organisationVerificationFormArray(): FormArray {
        return this.verificationFormGroup.get('organisationVerificationFormArray') as FormArray;
    }

    // You can add more methods to manipulate the form if needed
    public resetForm(): void {
        this.form.reset();
    }

    cloneFormGroup(formGroup: FormGroup): FormGroup {
        let cloned = new FormGroup({});
        Object.keys(formGroup.controls).forEach(key => {
            cloned.addControl(key, new FormControl(formGroup.controls[key].value));
        });
        return cloned;
    }
}