import { Injectable, WritableSignal, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CreativeFileModel, TabOptions } from '../models';

@Injectable({
    providedIn: 'root',
})
export class InternalService {
    private selectedFile = new BehaviorSubject<CreativeFileModel[] | null>(null);
    selectedTabSignal: WritableSignal<TabOptions | null> = signal(null);
    selectedCreativeTabSignal: WritableSignal<TabOptions | null> = signal(null);

    setSelectedFile(data: CreativeFileModel | null): void {
        const currentFiles = this.selectedFile.value || [];
        const updatedFiles = data ? [...currentFiles, data] : [...currentFiles]; // Add the new file to the existing array
        this.selectedFile.next(updatedFiles);
    }

    addOrUpdateFile(newFile: CreativeFileModel): void {
        const currentFiles = this.selectedFile.value || [];
        const newFileName = newFile.file.name;

        // Remove the old file with the same name
        const uniqueFiles = currentFiles.filter(f => f.file.name !== newFileName);

        // Add the new file to the list
        uniqueFiles.push(newFile);

        this.selectedFile.next(uniqueFiles);
    }

    getSelectedFile(): Observable<CreativeFileModel[] | null> {
        return this.selectedFile.asObservable();
    }

    updateLoadingStatusByMime(mime: string, loading: boolean, isError: boolean, error: string, file: File): void {
        const currentFiles = this.selectedFile.value;
        if (currentFiles) {
            const updatedFiles = currentFiles.map(f =>
                f.mime === mime ? { ...f, loading, isError, error, file } : f
            );
            this.selectedFile.next(updatedFiles);
        }
    }

    removeFileByMime(mime: string): void {
        const currentFiles = this.selectedFile.value;
        if (currentFiles) {
            const updatedFiles = currentFiles.filter(file => file.mime !== mime);
            this.selectedFile.next(updatedFiles);
        }
    }

    clearAllFiles(): void {
        this.selectedFile.next([]);
    }
}