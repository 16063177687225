import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { ChipsModel } from '../../models';

@Component({
    selector: 'advocate-ui-chips',
    templateUrl: 'chips.component.html',
    styleUrls: ['chips.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatChipsModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class ChipsComponent {
    @Input() chips: ChipsModel[] | null = null;
    @Input() chipName: string | null = null;
    @Input() disabled: boolean = false;
    @Input() withRemove: boolean = false;
    @Input() isSummaryChip: boolean = false;

    @Input() form: FormGroup = new FormGroup({});
    @Input() control: FormControl = new FormControl();
    @Input() controlArray: FormArray = this.fb.array([]);
    @Output() selectionChange = new EventEmitter<ChipsModel>();
    @Input() selectable: boolean = true;

    constructor(private fb: FormBuilder) { }

    remove(chip: ChipsModel): void {
        const index = this.controlArray.controls.findIndex(
            (control) => control.value.id === chip.id
        );

        if (index !== -1) {
            this.controlArray.removeAt(index);
        }
    }

    onChipSelectionChange(chip: ChipsModel): void {
        this.control.setValue(chip);
        this.selectionChange.emit(chip);
      }

}