<ng-container *ngIf="!disabled; else disabledRef">
    <form [formGroup]="form">
        <mat-chip-listbox *ngIf="!withRemove" [formControl]="control">
            <mat-chip-option *ngFor="let chip of chips" class="custom-flex"
                [class.selected]="control ? control.value?.label === chip.label : false"
                [selected]="control ? control.value?.label === chip.label : false" [disabled]="!selectable || disabled"
                (click)="selectable && onChipSelectionChange(chip)">
                <span [class.label-selected]="control ? control.value?.label === chip.label : false"
                    class="align-center">
                    {{chip.label}}
                </span>
            </mat-chip-option>
        </mat-chip-listbox>

        <mat-chip-listbox *ngIf="withRemove" #chipList>
            <mat-chip *ngFor="let chip of chips" removable="true" (removed)="remove(chip)">
                {{chip.label}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-listbox>
    </form>
</ng-container>

<ng-template #disabledRef>
    <mat-chip-listbox>
        <mat-chip> {{chipName ? chipName : 'N/A'}}
        </mat-chip>
    </mat-chip-listbox>
</ng-template>