<div class="container">
    <div class="breadcrumbs">
        <span class="breadcrumbs--in-active" (click)="navigateToHome()">Home</span>
        <span class="breadcrumbs--active">/</span>
        <span class="breadcrumbs--active">Campaign & reports</span>
    </div>
    <div class="container_header">
        <span>Group Performance</span>
    </div>
    <div *ngIf="{ data: (chartData$ | async)} as vm" class="container-body">
        <ng-container *ngIf="!editCampaign">
            <div class="container-body_filters">
                <div class="container-body_filters--search">
                    <advocate-ui-datepicker [isDisplayWhite]="true" #startDatePicker />
                    <advocate-ui-datepicker [isDisplayWhite]="true" #endDatePicker />
                    <advocate-ui-button [isCurved]="true" state="secondary" size="medium" name="Search"
                        (click)="searchDatePicker(startDatePicker, endDatePicker)"></advocate-ui-button>
                </div>
            </div>

            <ng-container *ngIf="vm.data?.metrics; else noData">
                <div class="container-body_graph">
                    <div class="container-body_graph--filter">
                        <advocate-ui-chips [chips]="chips" [control]="myFormControl"
                            (selectionChange)="changeChipSelection($event)"></advocate-ui-chips>
                    </div>
                    <advocate-ui-chart [data]="vm.data?.chart" [timestamps]="timestamps$ | async" />
                </div>
                <advocate-ui-matrics [data]="vm.data?.metrics" />
            </ng-container>

            <ng-template #noData>
                <div class="no-data">
                    <h3>No Data</h3>
                </div>
            </ng-template>

        </ng-container>
        <div class="container-body_info">
            <div class="container_header">
                <span>Campaign Information</span>
                <advocate-ui-button data-cy="edit-campaign-button" *ngIf="editCampaign" class="edit_campaign_btn"
                    [isCurved]="true" state="secondary" size="medium" (click)="onEditCampaign()"
                    name="Edit Campaign"></advocate-ui-button>
                <advocate-ui-button *ngIf="!editCampaign" class="edit_campaign_btn" [isCurved]="true" state="secondary"
                    size="medium" name="Request a more detailed report"
                    (click)="openReportDialog()"></advocate-ui-button>
            </div>
            <ng-container *ngIf="data$ | async as campaignData">
                <div class="key-value-container">
                    <div class="key-value-pair">
                        <span class="key">Advertiser</span>
                        <advocate-ui-chips [chips]="campaignData.advertiser_name" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">Name</span>
                        <advocate-ui-chips [chips]="campaignData.name" [selectable]="false" />
                    </div>
                    <hr>
                </div>

                <div class="container_header">
                    <span>Objective</span>
                </div>
                <div class="key-value-container">
                    <div class="key-value-pair">
                        <span class="key">Objective</span>
                        <advocate-ui-chips [chips]="campaignData.objective" [selectable]="false" />
                    </div>
                    <hr>
                </div>

                <div class="container_header">
                    <span>Budget & Dates</span>
                </div>
                <div class="key-value-container">
                    <div class="key-value-pair">
                        <span class="key">Budget</span>
                        <advocate-ui-chips [chips]="campaignData.budget" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">Dates</span>
                        <div class="value">
                            <advocate-ui-chips [chips]="campaignData.start_date_time" [selectable]="false" />
                            <span>to</span>
                            <advocate-ui-chips [chips]="campaignData.end_date_time" [selectable]="false" />
                        </div>
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">Running Days</span>
                        <advocate-ui-chips [chips]="campaignData.running_days" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">Running Time</span>
                        <div class="value">
                            <advocate-ui-chips [chips]="campaignData.running_time_from" [selectable]="false" />
                            <span>to</span>
                            <advocate-ui-chips [chips]="campaignData.running_time_to" [selectable]="false" />
                        </div>
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">Pacing</span>
                        <advocate-ui-chips [chips]="campaignData.pacing" [selectable]="false" />
                    </div>
                    <hr>
                </div>

                <div class="container_header">
                    <span>Targeting</span>
                </div>
                <div class="key-value-container">
                    <div class="key-value-pair">
                        <span class="key">Age Group</span>
                        <advocate-ui-chips [chips]="campaignData.age_groups" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">Gender</span>
                        <advocate-ui-chips [chips]="campaignData.genders" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">Saved Audience</span>
                        <advocate-ui-chips [chips]="campaignData.saved_audience" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">Advance targeting</span>
                        <advocate-ui-chips [chips]="campaignData.advance_targeting" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">Zip codes</span>
                        <advocate-ui-chips [chips]="campaignData.zip_codes" [selectable]="false" />
                    </div>
                    <hr>
                    <div class="key-value-pair">
                        <span class="key">Locations</span>
                        <advocate-ui-chips [chips]="campaignData.locations" [selectable]="false" />
                    </div>
                    <hr>
                </div>
            </ng-container>
        </div>
    </div>
</div>