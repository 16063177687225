export interface Payment {
    id: number;
    amount: number;
    created_at: string;
    status_id: number;
    status_label: string;
    platform_id: number;
    platform_label: string;
    user: User;
}

export interface User {
    id: number;
    name: string;
    email: string;
}

export interface PaymentData {
    payments: {
        items: Payment[];
        _links: {
            first: { href: string };
            last: { href: string };
            self: { href: string };
        };
        _meta: {
            totalCount: number;
            pageCount: number;
            currentPage: number;
            perPage: number;
        };
    }
}