import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { EnquiriesResponse, NotificationItemModel, NotificationResponse } from '../models/notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  private apiUrl = environment.apiKey;; // Replace with your API URL
  constructor(private http: HttpClient) { }

  getNotifications(orgId: number, userId: number, status_id?: number, perPage?: number): Observable<NotificationResponse> {
    const url = `${this.apiUrl}/organisations/${orgId}/users/${userId}/notifications`;

    const params: any = {};
    if (status_id) params.status_id = status_id;
    if (perPage) params.per_page = perPage;

    return this.http.get<NotificationResponse>(url, { params });
  }

  postNotificationActions(orgId: number, userId: number, notificationId: number): Observable<NotificationItemModel> {
    const body = {
      action: 'dismiss'
    };
    return this.http.post<NotificationItemModel>(`${this.apiUrl}/organisations/${orgId}/users/${userId}/notifications/${notificationId}/actions`, body);
  }

  postEnquiries(orgId: number, userId: number, body: EnquiriesResponse): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(`${this.apiUrl}/organisations/${orgId}/users/${userId}/enquiries`, body);
  }
}
