import { keywordItems } from "./creative.model";

export interface SearchCreative {
    id?: number;
    type_id?: number;
    type_label?: string;
    status_id?: number;
    status_label?: string;
    landing_url?: string;
    headline_1: string;
    headline_2: string;
    headline_3: string;
    description_1: string;
    description_2: string;
    additional_headlines?: string[];
    additional_descriptions?: string[];
}

export interface SearchStep2 {
    creative: SearchCreative;
}

export interface SearchStep3 {
    start_date: string;
    end_date: string;
    daily_budget: number;
    cpc_bid: number
}

export interface SearchStep4 {
    keywords: keywordItems[],
    negative_keywords: keywordItems[]
}


