import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment-timezone';
import { Observable, combineLatest, map } from 'rxjs';
import { Audience, CampaignDetailsInterface, CampaignItemInfo, ChipsModel, DatepickerComponentConfig, MetricsModel, OrganisationResponse } from '../../../models';
import { PrefillCampaignsService } from '../../../services/prefillCampaign.service';
import { ButtonComponent } from '../../../shared/button/button.component';
import { ChartComponent } from '../../../shared/chart/chart.component';
import { ChipsComponent } from '../../../shared/chips/chips.component';
import { DatepickerComponent } from '../../../shared/datepicker/datepicker.component';
import { MetricsComponent } from '../../../shared/tables/metrics/metrics.component';
import { ErrorHandlingViewModelService } from '../../view-model/error-handling-vm.service';
import { UtilityViewModelService } from '../../view-model/utility-vm.service';
import { CampaignDialogBoxComponent } from './campaign-dialog-box/campaign-dialog-box.component';

interface ChartDataWithTimestamps {
  timestamps: number[];
  chartData: Highcharts.SeriesOptionsType[];
  metrics: MetricsModel[]
}

@Component({
  selector: 'advocate-ui-campaign-table-info',
  standalone: true,
  imports: [CommonModule, ButtonComponent, DatepickerComponent, ChipsComponent, ChartComponent, MetricsComponent],
  templateUrl: './campaign-table-info.component.html',
  styleUrls: ['./campaign-table-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CampaignTableInfoComponent implements OnInit {
  data$?: Observable<CampaignDetailsInterface>;
  id: number = 0;
  campaignData?: CampaignItemInfo;
  editCampaign: boolean = true;
  chartData$!: Observable<{ metrics: MetricsModel[] | undefined, chart: Highcharts.SeriesOptionsType[] | undefined }>;
  timestamps$!: Observable<number[] | undefined>;

  chips: ChipsModel[] = [
    {
      id: 'Total spend',
      label: 'Total spend',
      selected: true
    },
    {
      id: 'Impressions served',
      label: 'Impressions served',
      selected: false
    },
    {
      id: 'Average VCR',
      label: 'Average VCR',
      selected: false
    },
    {
      id: 'eCPM',
      label: 'eCPM',
      selected: false
    },
    {
      id: 'Clicks',
      label: 'Clicks',
      selected: false
    },
    {
      id: 'Average CTR',
      label: 'Average CTR',
      selected: false
    }
  ];

  fetchChartData(startDate?: string, endDate?: string): Observable<ChartDataWithTimestamps | undefined> {
    return this.utilityVMService.getOrganisationMetricsEffect(startDate, endDate, +this.id)
      .pipe(map(response => {

        const timestamps = response.metrics.simplified.timestamps;

        const totalspendData: number[] = [];
        const impressions: number[] = [];
        const vcr: number[] = [];
        const ecpm: number[] = [];
        const clicks: number[] = [];
        const ctr: number[] = [];

        response?.metrics.full.forEach(data => {
          totalspendData.push(data.true_cost);
          impressions.push(data.impressions);
          vcr.push(data.vcr);
          ecpm.push(data.ecpm);
          clicks.push(data.clicks);
          ctr.push(data.ctr);
        });

        const chartData: Highcharts.SeriesOptionsType[] = [
          {
            name: 'Total spend',
            data: totalspendData,
            type: 'line',
          },
          {
            name: 'Impressions served',
            data: impressions,
            type: 'line',
          },
          {
            name: 'Average VCR',
            data: vcr,
            type: 'line',
          },
          {
            name: 'eCPM',
            data: ecpm,
            type: 'line',
          },
          {
            name: 'Clicks',
            data: clicks,
            type: 'line',
          },
          {
            name: 'Average CTR',
            data: ctr,
            type: 'line',
          },
        ];

        return { timestamps, chartData, metrics: response.metrics.full };
      }));
  }

  myFormControl = new FormControl(this.chips[0]);

  constructor(private router: Router, public dialog: MatDialog, private route: ActivatedRoute,
    private utilityVMService: UtilityViewModelService, private prefillCampaignsService: PrefillCampaignsService,
    private errorService: ErrorHandlingViewModelService) { }

  ngOnInit(): void {
    this.editCampaign = this.route.snapshot.queryParams['type'] === 'view' ? false : true;
    this.id = this.route.snapshot.params['id'];
    this.timestamps$ = this.fetchChartData().pipe(map(data => data?.timestamps));
    this.chartData$ = this.fetchChartData().pipe(
      map((data: ChartDataWithTimestamps | undefined) => ({
        chart: data?.chartData.filter(f => f.name === this.myFormControl.value?.label),
        metrics: data && data?.metrics.length > 0 ? data?.metrics : undefined
      })));

    this.data$ = combineLatest([
      this.utilityVMService.getCampaignDataEffect(+this.id),
      this.utilityVMService.getOrganisationDataEffect()])
      .pipe(
        map(([campaignData, organisationData]: [CampaignItemInfo, OrganisationResponse]) => {
          this.campaignData = campaignData;
          const audience: Audience | undefined = organisationData.organisation.audiences.find(a => a.id === campaignData.campaign.targeting.audience_id);
          const savedAudience: ChipsModel[] = [{
            id: 'saved_audience',
            label: audience?.name ?? 'N/A',
            selected: false
          }];
          const advancedTargeting: ChipsModel[] = [{
            id: 'premium_non_skippable_video',
            label: this.campaignData.campaign.targeting.has_premium_non_skippable_video ? 'Premium non skippable video' : 'N/A',
            selected: false
          },
          {
            id: 'premium_in_stream_video',
            label: this.campaignData.campaign.targeting.has_premium_in_stream_video ? 'Premium in stream video' : 'N/A',
            selected: false
          },
          {
            id: 'premium_placements',
            label: this.campaignData.campaign.targeting.has_premium_placements ? 'Premium placements' : 'N/A',
            selected: false
          },
          {
            id: 'premium_mobile_apps_only',
            label: this.campaignData.campaign.targeting.has_premium_mobile_apps_only ? 'Premium mobile apps only' : 'N/A',
            selected: false
          }].filter(f => f.label !== 'N/A');
          return {
            saved_audience: savedAudience,
            advance_targeting: advancedTargeting.length > 0
              ? advancedTargeting
              : [{
                id: 'N/A',
                label: 'N/A',
                selected: false
              }],
            locations: this.campaignData?.campaign.targeting.locations?.map(l => ({
              id: `${l.id}`,
              label: l.name,
              selected: false
            })),
            zip_codes: this.campaignData?.campaign.targeting.zip_codes?.map(z => ({
              id: z,
              label: z,
              selected: false
            })),
            advertiser_name: [{
              id: 'advertiser_name',
              label: this.formatValue(this.campaignData?.campaign.advertiser?.name),
              selected: false,
            }],
            name: [{
              id: 'name',
              label: this.formatValue(this.campaignData?.campaign.name),
              selected: false,
            }],
            objective: [{
              id: 'objective',
              label: this.formatValue(this.campaignData?.campaign.objective_label),
              selected: false,
            }],
            pacing: [{
              id: 'pacing',
              label: this.formatValue(this.campaignData?.campaign.budget_distribution_label),
              selected: false,
            }],
            genders: this.campaignData.campaign.targeting.genders?.map((gender: string) => ({
              id: gender,
              label: gender,
              selected: false,
            })) ?? [{ id: 'N/A', label: 'N/A', selected: false }],
            age_groups: this.campaignData?.campaign.targeting.age_groups?.map((age: string) => ({
              id: age,
              label: age,
              selected: false,
            })) ?? [{ id: 'N/A', label: 'N/A', selected: false }],
            education: this.campaignData?.campaign.targeting.education?.map((ed: string) => ({
              id: ed,
              label: ed,
              selected: false,
            })) ?? [{ id: 'N/A', label: 'N/A', selected: false }],
            running_days: this.campaignData?.campaign.running_days?.map((rd: string) => ({
              id: rd,
              label: rd,
              selected: false,
            })) ?? [{ id: 'N/A', label: 'N/A', selected: false }],
            budget: [
              { id: 'daily_budget', label: (this.formatValue(this.campaignData?.campaign.daily_budget)) + ' - Daily' },
              { id: 'lifetime_budget', label: (this.formatValue(this.campaignData?.campaign.lifetime_budget)) + ' - Lifetime' }
            ],
            start_date_time: [
              {
                id: 'start_date_time',
                label: (this.formatValue(this.campaignData?.campaign.start_date)) + ' ' + (this.formatValue(this.campaignData?.campaign.start_time)),
              }
            ],
            end_date_time: [
              {
                id: 'end_date_time',
                label: (this.formatValue(this.campaignData?.campaign.end_date)) + ' ' + (this.formatValue(this.campaignData?.campaign.end_time)),
              }
            ],
            running_time_from: [
              { id: 'running_time_from', label: this.formatValue(this.campaignData?.campaign.running_time_from) }
            ],
            running_time_to: [
              { id: 'running_time_to', label: this.formatValue(this.campaignData?.campaign.running_time_to) }
            ],
          };
        }));
  }

  formatValue(value: string | number | undefined | null) {
    if (value === "" || value === undefined || value === null) {
      value = 'N/A';
    }
    return value;
  }


  navigateToHome() {
    this.router.navigate(['/home']);
  }

  changeChipSelection(chip: ChipsModel): void {
    this.chartData$ = this.fetchChartData().pipe(
      map(data => ({
        chart: chip.id === 'All' ? data?.chartData : data!.chartData.filter(f => f.name === chip.id),
        metrics: data?.metrics
      }))
    );

    const index: number = this.chips.findIndex(c => c.id === chip.id);
    if (index !== -1) {
      this.chips = this.chips.map((c: ChipsModel, i: number) => ({
        ...c,
        selected: i === index ? !c.selected : false
      }));
    }
  }

  openReportDialog(): void {
    this.dialog.open(CampaignDialogBoxComponent, {
      width: '65rem',
      height: '39rem',
      data: { id: this.id }
    });
  }

  onEditCampaign(): void {
    this.prefillCampaignsService.prefill(this.campaignData, true);
    this.utilityVMService.initCampaignForecastData();
    this.errorService.searchKeywordErrorSignal.set(null);
  }

  searchDatePicker(startDate: DatepickerComponentConfig, endDate: DatepickerComponentConfig) {
    const updatedStartDate = moment(startDate.control.value).format('YYYY-MM-DD');
    const updatedEndDate = moment(endDate.control.value).format('YYYY-MM-DD');
    this.chartData$ = this.fetchChartData(updatedStartDate, updatedEndDate).pipe(map(data => ({
      chart: data?.chartData,
      metrics: data?.metrics
    })));
    this.timestamps$ = this.fetchChartData(updatedStartDate, updatedEndDate).pipe(map(data => data?.timestamps));
  }
}
