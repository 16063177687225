import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, WritableSignal, signal } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { catchError, of, switchMap, take, tap } from 'rxjs';
import { ResetPasswordModel } from '../../../models';
import { passwordMatchValidator } from '../../../services/validators';
import { ButtonComponent } from '../../../shared/button/button.component';
import { IconComponent } from '../../../shared/icon/icon.component';
import { AuthService } from '../auth.service';

@Component({
  selector: 'advocate-ui-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, IconComponent, ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordComponent {
  resetPasswordErrorSignal: WritableSignal<string | null> = signal(null);
  resetPasswordForm: FormGroup = this.fb.group({
    new_password: ['', [Validators.required]],
    repeat_new_password: ['', [Validators.required]],
  }, { validator: passwordMatchValidator('new_password', 'repeat_new_password') });

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private authService: AuthService, private router: Router) { }

  protected resetPassword(): void {
    this.route.queryParams.pipe(
      take(1),
      switchMap((params: Params) => {
        const token = params['token'];
        if (!token) return of(null);
        const resetPassword: ResetPasswordModel = {
          token,
          new_password: this.resetPasswordForm.get('new_password')?.value,
          repeat_new_password: this.resetPasswordForm.get('repeat_new_password')?.value
        };

        return this.authService.resetPassword(resetPassword).pipe(
          tap(_ => {
            this.resetPasswordErrorSignal.set(null);
            this.router.navigate(['/login']);
          })
        )
      }),
      catchError((error: HttpErrorResponse) => {
        console.warn('Reset password error: ', error);
        this.resetPasswordErrorSignal.set(error.error.message)
        return of(null);
      })
    ).subscribe();
  }

}
