<ng-container *ngIf="!isSVG; else svgRef">
    <ng-container *ngIf="type === 'filled';else outlined">
        <i class="material-icons-sharp cux-icon" [ngClass]="[size | iconSize, state | iconState]">
            {{name}}
        </i>
    </ng-container>
    
    <ng-template #outlined>
        <i class="material-icons-outlined cux-icon" [ngClass]="[size | iconSize, state | iconState]">
            {{name}}
        </i>
    </ng-template>
</ng-container>

<ng-template #svgRef>
    <img class="icon-svg" [src]="'../../assets/icons/'+svgName" [alt]="'Referencing icon for'+ svgName">
</ng-template>
