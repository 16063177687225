import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Component({
    standalone: true,
    imports: [CommonModule, MatSnackBarModule],
    selector: 'advocate-ui-impersonate-admin',
    template: ''
})
export class ImpersonateAdminComponent implements OnInit {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private snackBar: MatSnackBar
    ) { }

    ngOnInit(): void {
        const userId = this.route.snapshot.paramMap.get('userId') ?? '';
        this.authService.impersonateUser(userId).pipe(take(1)).subscribe({
            next: (_) => {
                this.router.navigate(['/home']);
                this.snackBar.open(`Impersonated the user ${_?.user.name}. Please logout to go back to admin login!`, 'Close');
            },
            error: (error: HttpErrorResponse) => {
                let message: string;
                switch (error.status) {
                    case 500: message = `Could not find user ID ${userId}`;
                        break;
                    case 403: message = 'Please login as Admin to avail impersonate feature';
                        break;
                    case 401: message = 'Please login first!';
                        break;
                    default: message = 'Failed loading, please try again';
                        break;
                }
                this.snackBar.open(message, 'Close', {
                    duration: 5000,
                    panelClass: 'error-snackbar',
                });
                this.router.navigate(['/home']);
            }
        });
    }
} 