<ng-container *ngIf="!isSecondary; else secondaryBtn">
    <button mat-raised-button class="cux-button" [class.cux-button--curved]="isCurved"
        [ngClass]="[state | buttonState, size | buttonSize]" type="button" [disabled]="disabled" (click)="onClick()">
        {{name}}
    </button>
</ng-container>

<ng-template #secondaryBtn>
    <button mat-stroked-button class="cux-button--secondary" [class.cux-button--curved]="isCurved"
        [ngClass]="[size | buttonSize]" type="button" [disabled]="disabled" (click)="onClick()">
        {{name}}
    </button>
</ng-template>