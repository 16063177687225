<h2>Payment details</h2>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  
    <!-- Header Column -->
    <ng-container matColumnDef="header">
      <td mat-cell *matCellDef="let element"> {{element.header}} </td>
    </ng-container>
  
    <!-- Details Column -->
    <ng-container matColumnDef="details">
      <td mat-cell *matCellDef="let element"> {{element.details}} </td>
    </ng-container>

    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>