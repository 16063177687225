import { Pipe, PipeTransform } from '@angular/core';
import { UIState } from '../utility';

// Decorator that defines a new standalone pipe named 'buttonState'
@Pipe({
  standalone: true,
  name: 'buttonState',
  pure: true
})
export class ButtonStatePipe implements PipeTransform {

  // @memo()
  transform(value: string): string {
    let uiStateClass = 'cux-button'; // Default class for buttons

    // Switch statement to assign specific class based on the UI state of the button
    switch (value) {
      case UIState.Primary: uiStateClass = 'cux-button--primary';
        break;

      case UIState.Secondary: uiStateClass = 'cux-button--secondary';
        break;

      case UIState.Warning: uiStateClass = 'cux-button--warning';
        break;

      case UIState.SpecialBack: uiStateClass = 'cux-button--special-back';
        break;

      case UIState.Disabled: uiStateClass = 'cux-button--disabled'; // Class for disabled buttons
        break;

    }
    // Returns the class name based on the button's UI state
    return uiStateClass;
  }

}
