import { Pipe, PipeTransform } from '@angular/core';

import { DefaultIconSize, IconSize } from './icon-size.enum';

@Pipe({
  standalone: true,
  name: 'iconSize'
})
export class IconSizePipe implements PipeTransform {
  transform(value: IconSize): string {
    return this.getIconSizeClass(value);
  }

  getIconSizeClass(size?: IconSize): string {
    size = size || DefaultIconSize;
    switch(size) {
        case IconSize.ExtraSmall:
            return 'cux-icon--extra-small';
        case IconSize.Medium:
            return 'cux-icon--medium';
        case IconSize.Large:
            return 'cux-icon--large';
        case IconSize.ExtraLarge:
            return 'cux-icon--extra-large';
        default:
            return '';
    }
  }

}
