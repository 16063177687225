import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {
    constructor(private router: Router) { }
    isLoggedIn = localStorage.getItem('isLoggedIn');

    canActivate(): boolean {
        if (this.isLoggedIn !== '1') {
            // User is logged in, allow access to the route.
            console.warn('Entered');

            return true;
        } else {
            // User is not logged in, redirect to the login page.
            this.router.navigate(['/home']);
            return false;
        }
    }
}
