export type UIState = '' | 'primary' | 'secondary' | 'success' | 'transparent' | 'warning' | 'danger' | 'Disabled' | 'info' | 'muted' | 'super-muted' | 'in-progress' | 'special-back';

export const UIState = {
    Default: '' as UIState,
    Primary: 'primary' as UIState,
    Secondary: 'secondary' as UIState,
    Success: 'success' as UIState,
    Transparent: 'transparent' as UIState,
    Warning: 'warning' as UIState,
    Danger: 'danger' as UIState,
    Disabled: 'disabled' as UIState,
    Info: 'info' as UIState,
    Muted: 'muted' as UIState,
    SuperMuted: 'super-muted' as UIState,
    InProgress: 'in-progress' as UIState,
    SpecialBack: 'special-back' as UIState
}

export const DefaultState = UIState.Primary; 