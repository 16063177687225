import { Pipe, PipeTransform } from '@angular/core';
import { ButtonSize, DefaultButtonSize } from './button-size.enum';

@Pipe({
  standalone: true,
  name: 'buttonSize',
  pure: true
})
export class ButtonSizePipe implements PipeTransform {
  transform(value: ButtonSize): string {
    return this.getButtonSizeClass(value);
  }

  // Helper method to get the corresponding CSS class for a given ButtonSize
  getButtonSizeClass(size?: ButtonSize): string {
    size = size || DefaultButtonSize;
    // Determine the class name based on the button size
    switch (size) {
      case ButtonSize.ExtraSmall:
        return 'cux-button--extra-small';
      case ButtonSize.Small:
        return 'cux-button--small';
      case ButtonSize.Medium:
        return 'cux-button--medium';
      case ButtonSize.Large:
        return 'cux-button--large';
      case ButtonSize.ExtraLarge:
        return 'cux-button--extra-large';
      default:
        return ''; // Return empty string if no matching size
    }
  }
}
