export interface NotificationItemModel {
  id: number;
  message: string;
  status_id: number;
  status_label: string;
  type_id: number;
  type_label: string;
  created_at: string;
  created_at_relative: string;
}

export interface NotificationResponse {
  notifications: {
    items: [
      NotificationItemModel
    ];
    _links: {
      self: { href: string };
      first: { href: string };
      last: { href: string };
    };
    _meta: {
      totalCount: number;
      pageCount: number;
      currentPage: number;
      perPage: number;
    };
  };
}

export interface EnquiriesResponse {
  name: string;
  email: string;
  phone?: string;
  subject: string;
  message: string;
  note?: string;
}
