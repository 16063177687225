import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, WritableSignal, signal } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { UtilityViewModelService } from '../../../../app/view-model/utility-vm.service';
import { AdvertiserErrorInterface } from '../../../../models';
import { AdvertiserModel } from '../../../../models/advertiser.model';
import { FormService } from '../../../../services/form.service';
import { ButtonComponent } from '../../../button/button.component';

@Component({
  selector: 'advocate-ui-advertiser-dialog',
  standalone: true,
  imports: [CommonModule, ButtonComponent, ReactiveFormsModule],
  templateUrl: './advertiser-dialog.component.html',
  styleUrls: ['./advertiser-dialog.component.scss']
})
export class AdvertiserDialogComponent {
  form: FormGroup = this.formService.advertiserDialogGroup;
  dialogTitle: string;
  private ngUnsubscribe = new Subject();
  public errorSignal: WritableSignal<AdvertiserErrorInterface | null> = signal(null);

  constructor(public dialogRef: MatDialogRef<AdvertiserDialogComponent>,
    private utilityVMService: UtilityViewModelService,
    private formService: FormService,
    @Inject(MAT_DIALOG_DATA) public data: { name?: string, debit?: number, id: number, mode: string }) {
    this.form.get('name')?.setValue(data.name);
    this.form.get('debit')?.setValue(data.debit);
    this.dialogTitle = data.mode === 'add' ? 'Create Advertiser' : `Advertiser: ${data.name}`;
  }

  saveEditedData(): void {
    const nameControl = this.form.get('name');
    const debitControl = this.form.get('debit');

    if (nameControl && debitControl && nameControl.value !== null && debitControl.value !== null) {
      const advDetails: AdvertiserModel = {
        name: nameControl.value,
        debit: debitControl.value,
      };

      if (this.data.mode === 'edit') {
        if (nameControl.value !== this.data.name || debitControl.value !== this.data.debit) {
          this.utilityVMService.editAdvertiserEffect(this.data.id, advDetails)
            .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
              next: () => {
                this.dialogRef.close(advDetails);
                this.errorSignal.set(null);
              },
              error: (error: HttpErrorResponse) => {
                if (error.status !== 422 || !error.error) {
                  this.errorSignal.set({ generalError: `${error.status}: ${error.statusText}` });
                  return;
                }
                const display: AdvertiserErrorInterface = error.error;
                this.errorSignal.set(display);
              }
            });
        }
      } else {
        this.utilityVMService.createAdvertiserEffect(advDetails).pipe(takeUntil(this.ngUnsubscribe))
          .subscribe({
            next: () => {
              this.dialogRef.close(advDetails);
              this.errorSignal.set(null);
            },
            error: (error: HttpErrorResponse) => {
              if (error.status !== 422 || !error.error) {
                this.errorSignal.set({ generalError: `${error.status}: ${error.statusText}` });
                return;
              }
              const display: AdvertiserErrorInterface = error.error;
              this.errorSignal.set(display);
            }
          });
      }
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.unsubscribe();
  }
}
