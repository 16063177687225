<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

    <!-- Source Date Column -->
    <ng-container matColumnDef="source_date">
        <th mat-header-cell *matHeaderCellDef> Source Date </th>
        <td mat-cell *matCellDef="let element"> {{element.source_date | date:'MMMM d, y'}} </td>
    </ng-container>

    <!-- Spend Column -->
    <ng-container matColumnDef="true_cost">
        <th mat-header-cell *matHeaderCellDef> Spend </th>
        <td mat-cell *matCellDef="let element">{{currencySignal()?.name}} {{element.true_cost}} </td>
    </ng-container>

    <!-- impressions Column -->
    <ng-container matColumnDef="impressions">
        <th mat-header-cell *matHeaderCellDef> Impressions served </th>
        <td mat-cell *matCellDef="let element"> {{element.impressions}} </td>
    </ng-container>

    <!-- ecpm Column -->
    <ng-container matColumnDef="ecpm">
        <th mat-header-cell *matHeaderCellDef> CPM </th>
        <td mat-cell *matCellDef="let element">{{currencySignal()?.name}} {{element.ecpm}} </td>
    </ng-container>

    <!-- cpc Column -->
    <ng-container matColumnDef="cpc">
        <th mat-header-cell *matHeaderCellDef> Cost per click </th>
        <td mat-cell *matCellDef="let element">{{currencySignal()?.name}} {{element.cpc}} </td>
    </ng-container>

    <!-- clicks Column -->
    <ng-container matColumnDef="clicks">
        <th mat-header-cell *matHeaderCellDef> Clicks </th>
        <td mat-cell *matCellDef="let element"> {{element.clicks}} </td>
    </ng-container>

    <!-- CTR Column -->
    <ng-container matColumnDef="ctr">
        <th mat-header-cell *matHeaderCellDef> Average click-through rate</th>
        <td mat-cell *matCellDef="let element"> {{element.ctr}} %</td>
    </ng-container>

    <!-- VCR Column -->
    <ng-container matColumnDef="vcr">
        <th mat-header-cell *matHeaderCellDef> Average video completion rate </th>
        <td mat-cell *matCellDef="let element"> {{element.vcr}} %</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>