import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth/auth.service';
import { LoginGuard } from './auth/login-guard';
import { LoginComponent } from './auth/login/login.component';
import { TokenRefreshInterceptor } from './auth/token-refresh-interceptor';
import { NewCampaignComponent } from './campaign/new-campaign.component';
import { LandingPageComponent } from './home/landing-page.component';
import { OrganisationGroupsComponent } from './organisation/organisation-groups.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // AppRoutingModule,

    NewCampaignComponent,
    OrganisationGroupsComponent,

    // Shared Standalone Components
    LoginComponent,
    LandingPageComponent,
    RouterModule.forRoot(routes),
  ],
  providers: [
    AuthService,
    LoginGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenRefreshInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
