<div class="container">
    <h2 class="container_header">Request a more detailed report</h2>
    <form class="advocate-form" [formGroup]="checkboxForm">
      <div class="advocate-form-row">
        <span>Click below to request more detailed information:</span>
        </div>
        <div class="advocate-form-row" formArrayName="options">
          <div *ngFor="let option of options; let i = index">
            <mat-checkbox color="primary" [formControlName]="i">
              {{ option }}
            </mat-checkbox>
          </div>
        </div>
    <div class="advocate-form-row">
      <span>Need something else? Contact us and we will do our best to get you that information.</span>
      </div>
      <div class="advocate-form-row advocate-form-row__button">
        <advocate-ui-button [isCurved]="true" state="secondary" size="medium"
        name="Contact us" (click)="sendRequest('contact_us')"></advocate-ui-button>
        <advocate-ui-button [isCurved]="true" state="primary" size="medium"
        name="Send Request" (click)="sendRequest('send_request')"></advocate-ui-button>
      </div>
    </form>
  </div>
  